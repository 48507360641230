import { makeStyles, Theme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2, 1)
  },
  cardSelected: {
    padding: theme.spacing(2, 1),
    borderTop: `25px solid ${theme.palette.secondary.main}`
  },
  dineOutCard: {
    backgroundColor: theme.palette.primary.main
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  pickerConatainer: {
    order: -1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      order: 1,
      alignSelf: 'flex-end',
      flexDirection: 'column'
    }
  },
  picker: {
    borderRadius: 5,
    padding: theme.spacing(1),
    color: 'white'
  },
  cardText: {
    marginBottom: theme.spacing(0.5)
  },
  yellow: {
    backgroundColor: theme.palette.primary.light
  },
  orange: {
    backgroundColor: theme.palette.warning.dark
  },
  red: {
    backgroundColor: theme.palette.error.main
  },
  green: {
    backgroundColor: theme.palette.success.main
  },
  blue: {
    backgroundColor: theme.palette.secondary.main
  },
  boldText: {
    fontWeight: 'bolder'
  },
  divider: {
    backgroundColor: grey[400]
  },
  tableServiceClosed: {
    backgroundColor: grey[300]
  }
}));
