import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { getNewOrderPickerBackgroundColor, getNewOrderTimeDifference, getProcessingOrderPickerBackgroundColor, getProcessingOrderTimeDifference } from './utils';
import { useStore } from 'store';
import { Card, Typography, Box } from '@material-ui/core';
import { useStyles } from '../style';
import { formatPhoneNumber } from 'util/number';
import { Link } from 'react-router-dom';

interface IProps {
  /** @property { string } - Unique identifier to identify order */
  orderId: string;
  /** @property { string | number } - Total amount of purchase */
  total: number | string;
  /** @property { number | string } - Phone number of buyer */
  phone: number | string;
  /** @property { string } - Name of buyer */
  name: string;
  /** @property { boolean } - If true card will be selectable otherwise redirect user to order information page */
  isSelectable?: boolean;
  /** @property { boolean } - Note: Only active when isSelectable is true. Defines whether card is selected or not */
  isSelected?: boolean;
  /** @property { Function } - Handler for setting isSelected Flag */
  setSelected?: (orderId: string, buyerId: string) => void;
  /** @property { string | number } - Tells at time order was created. It is of timestamp type */
  createdAt: string | number;
  /** @property { string } - UUID of order */
  orderNumber: string;
  buyerId: string;
  status: string;
  tableName?: string;
  partySize?: number;
}

const DineOutOrderCard = ({ orderId, orderNumber, total, phone, name, tableName, partySize, isSelectable = false, isSelected, setSelected, createdAt, buyerId, status }: IProps) => {
  const classes = useStyles();

  const {
    state: { restaurantId }
  } = useStore();

  const toPath = '/' + restaurantId + '/home/'.concat(orderId.toString());

  const [timeDifference, setTimeDifference] = useState(0);

  const [receivedIn, setReceivedIn] = useState<null | string>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentMoment = Moment();

      const createdAtMoment = Moment(createdAt);

      setReceivedIn(createdAtMoment.toNow(true).toString());

      const difference = Math.round(currentMoment.diff(createdAtMoment, 's') / 60);

      if (status === 'PLACED') {
        const _timeDiffernce = getNewOrderTimeDifference(difference);

        setTimeDifference(_timeDiffernce);
      } else {
        const _timeDiffernce = getProcessingOrderTimeDifference(difference);
        setTimeDifference(_timeDiffernce);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt, status, timeDifference]);

  const onClick = () => {
    if (setSelected) {
      setSelected(orderId, buyerId);
    }
  };

  const pickerClassName = timeDifference ? (classes as any)[status === 'PLACED' ? getNewOrderPickerBackgroundColor(timeDifference) : getProcessingOrderPickerBackgroundColor(timeDifference)] : null;

  const content = (
    <Card className={isSelectable && isSelected ? `${classes.cardSelected} ${classes.dineOutCard}` : `${classes.card} ${classes.dineOutCard}`}>
      <Box className={classes.cardContainer}>
        <Box flexGrow={1}>
          <Typography variant="body2" className={classes.cardText}>
            <span className={classes.boldText}>Order # :</span> {orderNumber}
          </Typography>
          <Typography variant="body2" className={classes.cardText}>
            <span className={classes.boldText}>Total :</span> ${total}
          </Typography>
          {phone && (
            <Typography variant="body2" className={classes.cardText}>
              <span className={classes.boldText}>Phone : </span>
              {formatPhoneNumber(phone.toString()) ? formatPhoneNumber(phone.toString()) : phone}
            </Typography>
          )}
          <Typography variant="body2" className={classes.cardText}>
            <span className={classes.boldText}>Nickname :</span> {name}
          </Typography>
        </Box>
        <Box className={classes.pickerConatainer}>
          <Box className={`${classes.picker} ${classes.blue}`} width="max-content" margin={1}>
            <Typography variant="body2">DINING</Typography>
            {tableName && <Typography variant="body2">Table {tableName}</Typography>}
            {partySize && <Typography variant="body2">People {partySize}</Typography>}
          </Box>
          {(status === 'PLACED' || status === 'ACCEPTED' || status === 'READY') && receivedIn && (
            <Box className={`${classes.picker} ${pickerClassName}`} border="solid 2px white" width="max-content">
              <Typography variant="body2">{receivedIn} ago</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );

  return (
    <Box width="100%" paddingY={1} onClick={onClick}>
      {isSelectable ? content : <Link to={{ pathname: toPath, state: { buyerId } }}>{content}</Link>}
    </Box>
  );
};

export default DineOutOrderCard;
