import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getRestaurantById from 'graphql/query/getRestaurantById.query';
import { useLazyQuery } from '@apollo/react-hooks';
import { useStore } from 'store';
import moment from 'moment-timezone';

export default () => {
  const { restaurantId } = useParams() as any;

  const { dispatch } = useStore();

  const [isValid, setValid] = useState<boolean>(false);

  const [getRestaurant, { data }] = useLazyQuery(getRestaurantById);

  const [restId, setResId] = useState(null);

  useEffect(() => {
    if (restId && restId === restaurantId) {
      return;
    } else {
      getRestaurant({
        variables: {
          input: {
            id: restaurantId
          }
        }
      });
    }
  }, [getRestaurant, restId, restaurantId]);

  useEffect(() => {
    if (data && data.getRestaurant) {
      const { id, i18n, opsSetting, enableTakeoutOrder, enableDeliveryOrder, name, subName } = data.getRestaurant;

      const { disableTakeoutOrderDetailDisplay = false, disableDeliveryOrderDetailDisplay = false, disableDineoutOrderDetailDisplay = false } = opsSetting || {
        disableTakeoutOrderDetailDisplay: false,
        disableDeliveryOrderDetailDisplay: false,
        disableDineoutOrderDetailDisplay: false
      };

      const timezone = i18n ? i18n.timezone || 'us/pacific' : 'us/pacific';
      const restaurantName = (name && name[0] && name[0].text) || '';
      const restaurantSubname = (subName && subName[0] && subName[0].text) || '';

      moment.tz.setDefault(timezone);

      setResId(id);

      dispatch({
        type: 'SET_RESTAURANT',
        payload: id
      });
      dispatch({
        type: 'SET_RESTAURANT_INFO',
        payload: {
          name: restaurantName,
          subName: restaurantSubname
        }
      });

      dispatch({
        type: 'SET_RESTAURANT_TIMEZONE',
        payload: timezone
      });

      dispatch({
        type: 'SET_RESTAURANT_OPS_SETTINGS',
        payload: {
          disableTakeoutOrderDetailDisplay,
          disableDeliveryOrderDetailDisplay,
          disableDineoutOrderDetailDisplay,
          enableTakeoutOrder,
          enableDeliveryOrder
        }
      });

      setValid(true);
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => dispatch({ type: 'RESET_RESTAURANT' });
  }, [dispatch]);

  return isValid;
};
