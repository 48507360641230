export const restaurantInitialState = {
  restaurantId: null,
  restaurantTimeZone: null,
  restaurantInfo: {
    name: '',
    subName: ''
  },
  opsSettings: {
    disableTakeoutOrderDetailDisplay: false,
    disableDeliveryOrderDetailDisplay: false,
    disableDineoutOrderDetailDisplay: false,
    enableTakeoutOrder: false,
    enableDeliveryOrder: false
  }
};

export const restaurantActions = {
  SET_RESTAURANT: (state: any, payload: any) => ({ ...state, restaurantId: payload }),
  SET_RESTAURANT_INFO: (state: any, payload: any) => ({ ...state, restaurantInfo: payload }),
  RESET_RESTAURANT: (state: any) => ({ ...state, restaurantId: null }),
  SET_RESTAURANT_TIMEZONE: (state: any, payload: any) => ({ ...state, restaurantTimeZone: payload }),
  RESET_RESTAURANT_TIMEZONE: (state: any) => ({ ...state, restaurantTimeZone: null }),
  SET_RESTAURANT_OPS_SETTINGS: (state: any, payload: any) => ({ ...state, opsSettings: payload }),
  RESET_RESTAURANT_OPS_SETTINGS: (state: any) => ({
    ...state,
    opsSettings: {
      disableTakeoutOrderDetailDisplay: false,
      disableDeliveryOrderDetailDisplay: false,
      disableDineoutOrderDetailDisplay: false,
      enableTakeoutOrder: false,
      enableDeliveryOrder: false
    }
  })
};

export default {};
