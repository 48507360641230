import React, { useState, useEffect } from 'react';
import {
  getTimeDifference,
  getNewOrderTimeDifference,
  getProcessingOrderTimeDifference,
  getNewOrderPickerBackgroundColor,
  getProcessingOrderPickerBackgroundColor,
  getPickerBackgroundColor
} from './utils';
import Moment from 'moment';
import { words } from 'lodash';
import { formatPhoneNumber } from 'util/number';
import { OrderCard as OrderCardUI, orderType, timeColor } from '@lokobee/lokobee-ui';
import { DeliveryProviders } from 'generated/custom';

interface IProps {
  name: string;
  phone: string;
  type: orderType;
  tableName?: string | undefined;
  partySize: number | undefined;
  deliveryDistance: number | undefined;
  itemCount: number;
  isSelected: boolean;
  expectedTime: number | string;
  createdAt: number | string;
  status: string;
  bgColor?: string;
  orderNumber: string;
  isNewOrder?: boolean;
  isProcessingOrder?: boolean;
  bizExpectTime?: number | string;
  deliveryProvider?: string | undefined;
}

const OrderCard = ({
  name,
  isNewOrder,
  isProcessingOrder,
  phone,
  type,
  tableName,
  partySize,
  deliveryDistance,
  itemCount,
  isSelected,
  expectedTime,
  createdAt,
  status,
  bgColor,
  orderNumber,
  bizExpectTime,
  deliveryProvider
}: IProps) => {
  const [remainingTime, setRemainingTime] = useState<null | string>(null);
  const [timeDifference, setTimeDifference] = useState(0);
  const [overdue, setOverdue] = useState(false);
  const [receivedIn, setReceivedIn] = useState<null | string>(null);
  const expectedTimeMoment = Moment(expectedTime);
  const createdAtMoment = Moment(createdAt);

  const difference = expectedTimeMoment.diff(createdAtMoment, 'm');
  const timeDifferenceType = difference <= 30 ? 'ASAP' : 'SCHEDULED';

  useEffect(() => {
    if (status === 'PLACED' || status === 'ACCEPTED' || status === 'READY') {
      const interval = setInterval(() => {
        const currentMoment = Moment();

        if (type === 'TAKEOUT' || type === 'DELIVERY') {
          /**
           * Calculates remaining time for pickup.
           */
          if (deliveryProvider && bizExpectTime && deliveryProvider === DeliveryProviders.DoordashClassic) {
            const bizExpectTimeMoment = Moment(bizExpectTime);
            const difference = +currentMoment.diff(bizExpectTimeMoment, 'm');

            const _timeDiffernce = getTimeDifference(difference);

            setTimeDifference(_timeDiffernce);
            if (bizExpectTimeMoment.isBefore(currentMoment)) {
              setRemainingTime(bizExpectTimeMoment.format('D MMM'));

              setOverdue(true);
            } else {
              let rt = bizExpectTimeMoment.from(currentMoment, true).toString();

              setOverdue(false);
              setRemainingTime(rt);
            }
          } else {
            const difference = +currentMoment.diff(expectedTimeMoment, 'm');

            const _timeDiffernce = getTimeDifference(difference);

            setTimeDifference(_timeDiffernce);
            if (expectedTimeMoment.isBefore(currentMoment)) {
              setRemainingTime(expectedTimeMoment.format('D MMM'));

              setOverdue(true);
            } else {
              let rt = expectedTimeMoment.from(currentMoment, true).toString();

              setOverdue(false);
              setRemainingTime(rt);
            }
          }
        }

        if (type === 'DINE_OUT') {
          const difference = Math.round(currentMoment.diff(createdAtMoment, 's') / 60);

          if (status === 'PLACED') {
            const _timeDiffernce = getNewOrderTimeDifference(difference);

            setTimeDifference(_timeDiffernce);
          } else {
            const _timeDiffernce = getProcessingOrderTimeDifference(difference);
            setTimeDifference(_timeDiffernce);
          }
          if (createdAtMoment.isBefore(currentMoment)) {
            const _receivedIn = createdAtMoment.toNow(true).toString();
            setReceivedIn(_receivedIn);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [bizExpectTime, createdAt, createdAtMoment, deliveryProvider, expectedTime, expectedTimeMoment, name, remainingTime, status, type]);

  let timeString = '';

  let pickerColor: timeColor = 'GREEN';

  if (status === 'PLACED' || status === 'ACCEPTED' || status === 'READY') {
    if (type === 'TAKEOUT' || type === 'DELIVERY') {
      const stringArray = words(remainingTime || undefined);
      timeString = !!remainingTime && !!stringArray && stringArray.length === 2 ? `${(stringArray as any)[0].toString()} ${(stringArray as any)[1].toString()}` : remainingTime || '';
      pickerColor = timeDifference ? getPickerBackgroundColor(timeDifference) : 'GREEN';
    }

    if (type === 'DINE_OUT') {
      timeString = receivedIn || '';
      pickerColor = timeDifference ? (status === 'PLACED' ? getNewOrderPickerBackgroundColor(timeDifference) : getProcessingOrderPickerBackgroundColor(timeDifference)) : 'GREEN';
    }
  } else {
    if (status === 'CLOSED') {
      timeString = 'Closed';
      pickerColor = 'GREEN';
    }
    if (status === 'CANCELLED') {
      timeString = 'Cancelled';
      pickerColor = 'RED';
    }
    if (status === 'REJECTED') {
      timeString = 'Rejected';
      pickerColor = 'RED';
    }
  }

  let customerPhone: string | null = '';

  if (phone) {
    customerPhone = formatPhoneNumber(phone) !== null ? formatPhoneNumber(phone) : phone;
  } else {
    customerPhone = 'Phone no. NA';
  }

  return (
    <OrderCardUI
      customerName={name}
      customerPhone={customerPhone ? customerPhone : ''}
      type={type}
      deliveryDistance={deliveryDistance}
      tableName={tableName}
      partySize={partySize}
      itemCount={itemCount}
      time={`${overdue ? 'overdue' : timeString}`}
      timeColor={pickerColor}
      isSelected={isSelected}
      bgColor={bgColor}
      orderNumber={orderNumber}
      isBlinking={overdue && (isProcessingOrder || isNewOrder)}
      timeDifferenceType={timeDifferenceType}
    />
  );
};

export default OrderCard;
