import { gql } from 'apollo-boost';

export default gql`
  query getRestaurant($input: GetRestaurantInput!) {
    getRestaurant(input: $input) {
      id
      name {
        text
      }
      subName {
        text
      }
      i18n {
        timezone
      }
      opsSetting {
        disableTakeoutOrderDetailDisplay
        disableDeliveryOrderDetailDisplay
        disableDineoutOrderDetailDisplay
      }
      enableTakeoutOrder
      enableDeliveryOrder
    }
  }
`;
